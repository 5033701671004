<template>
  <!-- 基本信息 -->
  <div class="pad-container">
    <el-row class="mb-20 pad-container" v-if="identityType == 0 || identityType == 1">
      <el-button
        size="medium"
        type="primary"
        class="form-button"
        @click="handleEdit"
        v-if="!editFlag"
        >编辑</el-button
      >
      <template v-else>
        <el-button size="medium" class="form-button" @click="handleCancel"
          >取消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          class="form-button"
          @click="handleSave"
          >保存</el-button
        >
      </template>
    </el-row>
    <el-row class="pad-container">
      <el-button
        size="medium"
        type="primary"
        class="mb-20"
        @click="handleAdd(2)"
        v-if="editFlag"
      >
        <i class="el-icon-plus mr-10"></i>添加商超商品一级分类
      </el-button>
      <el-button
        size="medium"
        type="primary"
        class="mb-20 ml-10"
        @click="handleAdd(1)"
        v-if="editFlag"
      >
        <i class="el-icon-plus mr-10"></i>添加生鲜商品一级分类
      </el-button>
      <el-form :model="act_form" :rules="rules" ref="act_form">
        <el-table
          :data="act_form.treeData"
          style="width: 100%"
          :default-expand-all="true"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="child">
                <el-form-item
                  v-for="(item1, index1) in scope.row.childList"
                  :key="item1.id"
                  class="ml-80 mb-20"
                  :prop="
                    'treeData.' +
                    scope.$index +
                    '.childList.' +
                    index1 +
                    '.classifyName'
                  "
                  :rules="[
                    {
                      message: '内容不能为空',
                      required: true,
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="item1.classifyName"
                    class="form-input-300"
                    :disabled="!editFlag"
                  ></el-input>
                  <span class="ml-10" v-show="editFlag">
                    <el-button
                      type="text"
                      size="medium"
                      @click="append(scope.row.childList)"
                      v-if="item1.childList"
                      >新增分类</el-button
                    >
                    <el-button
                      type="text"
                      size="medium"
                      @click="remove(item1.id, index1, scope.row)"
                    >
                      <i class="el-icon-delete mr-5"></i>
                      删除
                    </el-button>
                    <el-button
                      icon="el-icon-arrow-left"
                      size="small"
                      v-if="index1 != 0"
                      @click="handleUp(index1, scope.row.childList)"
                      >上一级</el-button
                    >
                    <el-button
                      size="small"
                      v-if="index1 < scope.row.childList.length - 1"
                      @click="handleDown(index1, scope.row.childList)"
                    >
                      下一级
                      <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                  </span>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分类名称">
            <template slot-scope="scope">
              <el-row class="flx-row ali-c">
                {{ scope.row.goodsType == 1 ? "生鲜" : "商超" }}
                <el-form-item
                  :prop="'treeData.' + scope.$index + '.classifyName'"
                  :rules="[
                    {
                      message: '内容不能为空',
                      required: true,
                      trigger: 'blur',
                    },
                  ]"
                  :key="scope.row.id"
                  class="ml-15"
                >
                  <el-input
                    v-model="scope.row.classifyName"
                    class="form-input-300"
                    :disabled="!editFlag"
                  ></el-input>
                </el-form-item>
                <span class="ml-10" v-show="editFlag">
                  <el-button
                    type="text"
                    size="medium"
                    @click="append(scope.row)"
                    v-if="scope.row.childList"
                    >新增分类</el-button
                  >
                  <el-button
                    type="text"
                    size="medium"
                    @click="
                      remove(scope.row.id, scope.$index, act_form.treeData)
                    "
                  >
                    <i class="el-icon-delete mr-5"></i>
                    删除
                  </el-button>
                  <el-button
                    icon="el-icon-arrow-left"
                    size="small"
                    v-if="scope.$index != 0"
                    @click="handleUp(scope.$index)"
                    >上一级</el-button
                  >
                  <el-button
                    size="small"
                    v-if="scope.$index < act_form.treeData.length - 1"
                    @click="handleDown(scope.$index)"
                  >
                    下一级
                    <i class="el-icon-arrow-right el-icon--right"></i>
                  </el-button>
                </span>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column
            label="一级分类图片"
            prop="name"
            :current-row-key="Math.random()"
          >
            <template slot-scope="scope">
              <div v-if="editFlag">
                <el-form-item
                  label
                  class="ml-10"
                  :prop="'treeData.' + scope.$index + '.picture'"
                  :rules="[
                    {
                      message: '图片不能为空',
                      required: true,
                      trigger: 'blur',
                    },
                  ]"
                  :key="scope.row.id"
                >
                  <UploadPics
                    :uploadType="'main'"
                    :imgLimit="1"
                    :imgSize="'94*94'"
                    :hideText="true"
                    :imgsPreview="scope.row.picture"
                    @getImgData="getImgData"
                    :imgIndex="scope.$index"
                    :key="Math.random()"
                  />
                  <span class="c-6" v-if="!scope.row.picture"
                    >建议尺寸：94*94</span
                  >
                </el-form-item>
              </div>
              <el-form-item v-else class="ml-20 img-show-wrap">
                <img
                  :src="picsPath(scope.row.picture) || defaultImg"
                  alt
                  :key="Math.random()"
                  class="img-show"
                />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import UploadPics from "@/components/UploadPics";
import { mapGetters } from "vuex";
import defaultImg from "@/assets/defaultImg.png";
import {queryGoodsType,saveGoodsType,delGoodsFreshType} from "@/api/market/classify_list"; 
export default {
  components: {
    UploadPics,
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  data() {
    return {
      defaultImg,
      mainImgStr: "",
      list: [
        {
          id: 1,
          label: "一级 1",
          img:
            "http://i1.sinaimg.cn/dy/c/2012-05-06/U7724P1T1D24374571F21DT20120506113521.jpg",
          child: [
            {
              id: 4,
              label: "二级 1-1",
            },
            {
              id: 5,
              label: "二级 1-2",
            },
          ],
          type: 1,
        },
        {
          id: 2,
          label: "一级 2",
          img: "",
          child: [
            {
              id: 3,
              label: "二级 2-1",
            },
            {
              id: 6,
              label: "二级 2-2",
            },
          ],
          type: 2,
        },
      ],
      act_form: {
        treeData: [],
      },
      rules: {
        treeData: [
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
      },
      editFlag: false,
      // defaultProps: {
      //   children: "childList",
      // },
      identityType:-1 , //0管理员  1运营  2财务  3 门店管理人员
    };
  },
  mounted() {
    //  // console.log(this.roles, "roles");
     // console.log(this.$store.getters.userObject)
    this.identityType = this.$store.getters.userObject.userRoleVos[0].type;
    this.getData();
  },
  methods: {
    getData() {
      queryGoodsType().then((res) => {
        this.act_form.treeData = res.data.body;
      });
    },
    handleUp(i, data) {
      let arr = data ? data : this.act_form.treeData;
      [arr[i - 1], arr[i]] = [arr[i], arr[i - 1]];
      this.$set(arr, i - 1, arr[i - 1]);
      this.$set(arr, i, arr[i]);
    },
    handleDown(i, data) {
      let arr = data ? data : this.act_form.treeData;
      [arr[i + 1], arr[i]] = [arr[i], arr[i + 1]];
      this.$set(arr, i - 1, arr[i - 1]);
      this.$set(arr, i, arr[i]);
    },
    // 获取图片数据
    getImgData(imgData, type, params, imgIndex) {
      if (type == "main") {
        let data = this.act_form.treeData;
        data[imgIndex].picture = imgData.mainImg;
        this.$set(data, imgIndex, data[imgIndex]);
      }
    },
    handleAdd(type) {
      const newChild = {
        parentId: 0,
        classifyName: "",
        childList: [],
        goodsType: type,
      };
      this.act_form.treeData.unshift(newChild);
      // console.log(this.act_form.treeData);
    },
    append(data) {
      // console.log(data, "data");
      const newChild = {
        classifyName: "",
        goodsType: data.goodsType,
      };
      data.childList.unshift(newChild);
    },
    // 删除分类
    removeClassify(id) {
      delGoodsFreshType(id).then((res) => {
        this.$message.success(res.data.body);
      });
    },
    remove(id, index, parent) {
      let data = parent.childList ? parent.childList[index] : parent[index];
      if (data.childList && data.childList.length != 0) {
        this.$message.warning("该分类下有子分类，无法删除，请先删除子分类");
        return;
      }

      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(async () => {
        if (id) {
          await this.removeClassify(id);
          parent.childList
            ? parent.childList.splice(index, 1)
            : parent.splice(index, 1);
        } else {
          parent.childList
            ? parent.childList.splice(index, 1)
            : parent.splice(index, 1);
        }
      });
    },
    //   编辑
    handleEdit() {
      this.editFlag = true;
    },
    handleCancel() {
      this.$refs.act_form.resetFields();
      this.getData();
      this.editFlag = false;
      // console.log(this.act_form, "handleCancel");
    },
    handleSave() {
      // console.log(this.act_form.treeData, "save");
      this.$refs["act_form"].validate((valid) => {
        if (!valid) {
          this.$message.warning("表单信息不完整（有误）");
          return;
        }
        this.submitForm();
      });
    },
    // 改变数据结构
    //       classifyName	string	true	普通参数		分类名
    //       parentId	string	true	普通参数		一级分类id 本身为一级分类则为0
    //       classifySort	string	true	普通参数		分类排序
    //       goodsType	string	true	普通参数		商品类型：1生鲜商品2丽日商品
    //          childList	string	true	普通参数		子分类
    //          classifyName	string	true	普通参数
    //          classifySort	string	true	普通参数
    //          goodsType
    submitForm() {
      // console.log(this.act_form.treeData)
      saveGoodsType(this.act_form.treeData).then(res=>{
      this.$message.success(res.data.body);
          this.handleCancel()
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table /deep/ .cell {
  overflow: visible;
  margin-bottom: 10px;
}
.pad-container /deep/ .el-tree-node__content {
  height: 40px;
  margin-bottom: 10px;
}
.toggle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: all 0.3s;
}
.icon-show {
  transition: all 0.3s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.el-form-item /deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 88px;
}
.el-form-item /deep/ .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 88px;
  margin: 0;
  transition: none !important;
}
.img-show-wrap /deep/ .el-form-item__content {
  line-height: 0;
}
.img-show {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  border: 1px solid #c0ccda;
}
.el-form /deep/ .el-form-item {
  margin-bottom: 0;
}
.child /deep/ .el-form-item:last-child {
  margin-bottom: 0;
}
.child /deep/ .el-form-item {
  margin-bottom: 20px;
}
.el-form /deep/ .el-upload-list--picture-card {
  line-height: 0;
}
</style>