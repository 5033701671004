var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pad-container"},[(_vm.identityType == 0 || _vm.identityType == 1)?_c('el-row',{staticClass:"mb-20 pad-container"},[(!_vm.editFlag)?_c('el-button',{staticClass:"form-button",attrs:{"size":"medium","type":"primary"},on:{"click":_vm.handleEdit}},[_vm._v("编辑")]):[_c('el-button',{staticClass:"form-button",attrs:{"size":"medium"},on:{"click":_vm.handleCancel}},[_vm._v("取消")]),_c('el-button',{staticClass:"form-button",attrs:{"size":"medium","type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("保存")])]],2):_vm._e(),_c('el-row',{staticClass:"pad-container"},[(_vm.editFlag)?_c('el-button',{staticClass:"mb-20",attrs:{"size":"medium","type":"primary"},on:{"click":function($event){return _vm.handleAdd(2)}}},[_c('i',{staticClass:"el-icon-plus mr-10"}),_vm._v("添加商超商品一级分类 ")]):_vm._e(),(_vm.editFlag)?_c('el-button',{staticClass:"mb-20 ml-10",attrs:{"size":"medium","type":"primary"},on:{"click":function($event){return _vm.handleAdd(1)}}},[_c('i',{staticClass:"el-icon-plus mr-10"}),_vm._v("添加生鲜商品一级分类 ")]):_vm._e(),_c('el-form',{ref:"act_form",attrs:{"model":_vm.act_form,"rules":_vm.rules}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.act_form.treeData,"default-expand-all":true}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"child"},_vm._l((scope.row.childList),function(item1,index1){return _c('el-form-item',{key:item1.id,staticClass:"ml-80 mb-20",attrs:{"prop":'treeData.' +
                  scope.$index +
                  '.childList.' +
                  index1 +
                  '.classifyName',"rules":[
                  {
                    message: '内容不能为空',
                    required: true,
                    trigger: 'blur',
                  },
                ]}},[_c('el-input',{staticClass:"form-input-300",attrs:{"disabled":!_vm.editFlag},model:{value:(item1.classifyName),callback:function ($$v) {_vm.$set(item1, "classifyName", $$v)},expression:"item1.classifyName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.editFlag),expression:"editFlag"}],staticClass:"ml-10"},[(item1.childList)?_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":function($event){return _vm.append(scope.row.childList)}}},[_vm._v("新增分类")]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":function($event){return _vm.remove(item1.id, index1, scope.row)}}},[_c('i',{staticClass:"el-icon-delete mr-5"}),_vm._v(" 删除 ")]),(index1 != 0)?_c('el-button',{attrs:{"icon":"el-icon-arrow-left","size":"small"},on:{"click":function($event){return _vm.handleUp(index1, scope.row.childList)}}},[_vm._v("上一级")]):_vm._e(),(index1 < scope.row.childList.length - 1)?_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.handleDown(index1, scope.row.childList)}}},[_vm._v(" 下一级 "),_c('i',{staticClass:"el-icon-arrow-right el-icon--right"})]):_vm._e()],1)],1)}),1)]}}])}),_c('el-table-column',{attrs:{"label":"分类名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-row',{staticClass:"flx-row ali-c"},[_vm._v(" "+_vm._s(scope.row.goodsType == 1 ? "生鲜" : "商超")+" "),_c('el-form-item',{key:scope.row.id,staticClass:"ml-15",attrs:{"prop":'treeData.' + scope.$index + '.classifyName',"rules":[
                  {
                    message: '内容不能为空',
                    required: true,
                    trigger: 'blur',
                  },
                ]}},[_c('el-input',{staticClass:"form-input-300",attrs:{"disabled":!_vm.editFlag},model:{value:(scope.row.classifyName),callback:function ($$v) {_vm.$set(scope.row, "classifyName", $$v)},expression:"scope.row.classifyName"}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.editFlag),expression:"editFlag"}],staticClass:"ml-10"},[(scope.row.childList)?_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":function($event){return _vm.append(scope.row)}}},[_vm._v("新增分类")]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":function($event){return _vm.remove(scope.row.id, scope.$index, _vm.act_form.treeData)}}},[_c('i',{staticClass:"el-icon-delete mr-5"}),_vm._v(" 删除 ")]),(scope.$index != 0)?_c('el-button',{attrs:{"icon":"el-icon-arrow-left","size":"small"},on:{"click":function($event){return _vm.handleUp(scope.$index)}}},[_vm._v("上一级")]):_vm._e(),(scope.$index < _vm.act_form.treeData.length - 1)?_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.handleDown(scope.$index)}}},[_vm._v(" 下一级 "),_c('i',{staticClass:"el-icon-arrow-right el-icon--right"})]):_vm._e()],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"一级分类图片","prop":"name","current-row-key":Math.random()},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editFlag)?_c('div',[_c('el-form-item',{key:scope.row.id,staticClass:"ml-10",attrs:{"label":"","prop":'treeData.' + scope.$index + '.picture',"rules":[
                  {
                    message: '图片不能为空',
                    required: true,
                    trigger: 'blur',
                  },
                ]}},[_c('UploadPics',{key:Math.random(),attrs:{"uploadType":'main',"imgLimit":1,"imgSize":'94*94',"hideText":true,"imgsPreview":scope.row.picture,"imgIndex":scope.$index},on:{"getImgData":_vm.getImgData}}),(!scope.row.picture)?_c('span',{staticClass:"c-6"},[_vm._v("建议尺寸：94*94")]):_vm._e()],1)],1):_c('el-form-item',{staticClass:"ml-20 img-show-wrap"},[_c('img',{key:Math.random(),staticClass:"img-show",attrs:{"src":_vm.picsPath(scope.row.picture) || _vm.defaultImg,"alt":""}})])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }